import Button from '@components/Button/index';
import Navigation from '@components/Navigation/index';
import React from 'react';
import * as animationData from '../animations/404/404-Error.json';
import Lottie from 'react-lottie';
import useResizer from '@components/extra/useResizer';
import { Link } from 'gatsby';

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: animationData,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

export default function ErrorPage() {
  const isMobile = useResizer();

  return (
    <div className="flex h-screen flex-col">
      <Navigation />
      <div className="mt-[105px] flex h-full w-full flex-1 flex-col items-center justify-center gap-5 px-4 text-center">
        <Lottie
          options={defaultOptions}
          height={isMobile ? 300 : 400}
          width={isMobile ? 300 : 400}
        />

        <div className="flex -translate-y-8 flex-col items-center justify-center gap-4 text-center">
          <div className="text-3xl font-medium text-v5-neutral-600 md:text-4xl">
            Oops! This page doesn't exist
          </div>
          <div className="text-lg text-v5-neutral-400 md:text-xl">
            We are very sorry for the inconvenience. It looks like you are
            trying to access a page that has been
            <br />
            deleted or never even existed.
          </div>
          <Link to="/">
            <Button
              disableRipple
              className={`natural sm flex h-[56px] items-center  whitespace-nowrap  rounded-[10px] border-primary-200 bg-primary-200  px-4 py-2 font-manrope text-base font-bold capitalize text-black shadow-lg shadow-primary-200/50 hover:bg-primary-100 hover:shadow-xl hover:shadow-primary-100/50`}
              type="contained"
            >
              Back To Home Page
            </Button>
          </Link>
        </div>
      </div>
    </div>
  );
}
